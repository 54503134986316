'use client';

import { useTranslation } from 'react-i18next';
import { Button } from '@cxnpl/ui';
import { useRouter } from '@cxnpl/router';
import branding from '@cxnpl/ui/brand';
import { FullScreenError } from './components';

export function InvalidSessionError() {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <FullScreenError
      variant="with-logo-and-background"
      title={t('common.errorBoundary.title.invalidSession')}
      message={t('common.errorBoundary.message.invalidSession', { productName: branding.productName })}
    >
      <Button
        onPress={() => {
          router.push('/login');
        }}
      >
        {t('common.errorBoundary.buttons.backToLogin')}
      </Button>
    </FullScreenError>
  );
}
